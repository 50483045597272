import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import "./config";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";
import "moment/locale/th";
import { ConfigProvider } from "antd";
import thTH from "antd/lib/locale-provider/th_TH";

React.icons = icons;

ReactDOM.render(
  <ConfigProvider locale={thTH}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
