import { createStore } from "redux";

const initialState = {
  sidebarShow: "responsive",
  showAlert: {
    deposit: { status: false, message: "" },
    withdraw: { status: false, message: "" },
  },
  trasactionData: [],
  gameCreditData: [],
  reloadWithDrawReq: false,
  reloadDeposit: false,
  depositNotiCounter: 0,
  withdrawReqCounter: 0,
  newMemberNotiCounter: 0,
  systemImage: {},
  serverConfig: {},
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "setMode":
      return { ...state, ...rest };
    case "SET_SYSTEM_IMAGE":
      return { ...state, ...rest };

    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
