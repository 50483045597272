import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loading from "src/components/Loading";
import { CONFIG_RULES } from "src/context/constant";
import SystemService from "src/services/SystemService";
import { useDispatch } from "react-redux";
import "./scss/style.scss";

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const UILogin = React.lazy(() => import("./views/pages/Login/UILogin"));
const Page404 = React.lazy(() => import("./views/pages/404/Page404"));

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    SystemService.getImageConfigById([
      CONFIG_RULES.BACKGROUND_IMAGE,
      CONFIG_RULES.ICON_IMAGE,
      CONFIG_RULES.BANNER_IMAGE,
    ]).then(({ data }) => {
      dispatch({ type: "SET_SYSTEM_IMAGE", systemImage: data });
      dispatch({ type: "TRIGGER_IMAGE_RELOAD", reloadSystemImage: false });
    });
  }, []);

  return (
    <BrowserRouter>
      <React.Suspense fallback={Loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login"
            render={(props) => <UILogin {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
