import $ from "jquery";
import { StaffService } from "../services/UserServices";
import CryptoJS from "crypto-js";

const timeTwoDigit = (num) => {
  return (num < 10 ? "0" : "") + num;
};

export const formatDateTime = (time, type) => {
  let d = new Date(time);
  let h = timeTwoDigit(d.getHours());
  let m = timeTwoDigit(d.getMinutes());
  let s = timeTwoDigit(d.getSeconds());

  if (type === "onlyTime") {
    return `${h}:${m}:${s}`;
  } else if (type) {
    return `${d.toLocaleDateString("th-TH")}`;
  }
  return `${d.toLocaleDateString("th-TH")} - ${h}:${m}:${s}`;
};

export const getStorage = (key) => {
  return sessionStorage.getItem(key);
};

export const getSecretAuth = (key) => {
  let apiURL = new URL(key);
  let apireverse = apiURL.host.toLowerCase().split("").reverse().join("");
  let secret = CryptoJS.MD5(apireverse).toString();
  return secret;
};

export const getDefaultValue = (value) => {
  let val = parseFloat(value).toFixed(2);
  let temp = val.split(".");
  let itg = parseInt(temp[0]).toLocaleString();
  let digit = temp[1];

  return value ? `${itg}.${digit}` : "0.00";
};
