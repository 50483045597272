import axios from "axios";

const ENDPOINT = {
  USER: {
    LOGIN: `${window.env.REACT_APP_DEV_ENDPOINT}/member-hook/login`,
  },
};

// ==== MEMBER SERVICE ===== //
export const MemberService = {
  login: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.USER.LOGIN,
      data,
    });
  },
};
