export const PUBLIC_IMAGES_URL = {
  BANK: `${window.env.REACT_APP_DEV_ENDPOINT}/images/bank`,
  PROMOTION: `${window.env.REACT_APP_DEV_ENDPOINT}/images/promotion`,
  CONFIG: `${window.env.REACT_APP_DEV_ENDPOINT}/images/config`,
  REWARD: `${window.env.REACT_APP_DEV_ENDPOINT}/images/reward-point`,
};

export const STATEMENT_STATUS = {
  FAILED: "failed",
  WAITING: "wating",
  IN_PROCESS: "inprocess",
  SUCCESS: "success",
  UNKNOWN: "unknown",
};

export const CONFIG_RULES = {
  FIXED_DEPOSIT_SWITCH: "001",
  POINT_TURN_OVER: "002",
  MIN_TOPUP: "003",
  MIN_WITHDRAW: "004",
  MANUAL_SET_MAX_BALANCE: "005",
  AUTO_MIN_WITHDRAW: "006",
  AUTO_MAX_WITHDRAW: "007",
  POINT_TO_BALANCE: "008",
  BALANCE_TO_POINT: "009",
  POINT_TO_SPIN: "010",
  REFUND_CREDIT_PERCENT: "011",
  QUICK_AUTO_TRANSFER: "012",
  SERVER_STATUS: "013",
  SERVER_MESSAGE: "014",
  SERVER_MESSAGE_ADMIN: "015",
  SERVER_LINE_CONTACT: "016",
  MAX_FRIEND_INVITE: "017",
  PERCENT_FROM_INVITE: "018",
  FREE_CREDIT: "019",
  MIN_RECEIVE_BALANCE: "020",
  AFFILIATE_DETAIL: "021",
  DAY_START: "022",
  DAY_END: "023",
  NIGHT_START: "024",
  NIGHT_END: "025",
  AFTERNOON_START: "026",
  AFTERNOON_END: "027",
  JSON_DATA: "028",
  BACKGROUND_IMAGE: "029",
  AVATAR_IMAGE: "030",
  FIXED_DEPOSIT_IMAGE: "031",
  ICON_IMAGE: "032",
  FIXED_DEPOSIT_VALUE: "033",
  SYSTEM_BALANCE: "034",
  LINE_TOKEN: "035",
  RICH_LOGIN: "036",
  RICH_NO_LOGIN: "037",
  JACKPOT_WHEEL_STATUS: "038",
  BANNER_IMAGE: "039",
};
