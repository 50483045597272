import axios from "axios";
import { getStorage } from "../utils/utils";

const ENDPOINT = {
  SYSTEM: {
    GET_CONFIG: `${window.env.REACT_APP_DEV_ENDPOINT}/system-config/find-config`,
    GET_IMAGE_COMFIG: `${window.env.REACT_APP_DEV_ENDPOINT}/system-config/find-image`,
  },
};

const SystemService = {
  // ==== SYSTEM CONFIG ==== //
  getConfigById: (configRequest) => {
    return axios({
      method: "POST",
      url: ENDPOINT.SYSTEM.GET_CONFIG,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: configRequest,
    });
  },

  // ==== SYSTEM IMAGE CONFIG ==== //
  getImageConfigById: (imageReuest) => {
    return axios({
      method: "POST",
      url: ENDPOINT.SYSTEM.GET_IMAGE_COMFIG,
      data: imageReuest,
    });
  },
};

export default SystemService;
