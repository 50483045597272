import { Spin } from "antd";

const Loading = (
  <div
    style={{
      height: "75vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin size="large" />
  </div>
);

export default Loading;
